import { onUnmounted, ref } from "vue";

export const useDebounce = (func, timeout) => {
  const timeoutRef = ref(undefined);
  onUnmounted(() => {
    clearTimeout(timeoutRef.value);
  });
  return (...args) => {
    clearTimeout(timeoutRef.value);
    timeoutRef.value = setTimeout(() => func(...args), timeout);
  };
};
