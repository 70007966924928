import { collection, where, query, getDocs } from "firebase/firestore";
import { db } from "../db";

export const getMediaForJob = async (jobId) => {
  return (
    await getDocs(
      query(collection(db, "media"), where("job", "==", `jobs/${jobId}`))
    )
  ).docs.map((d) => ({ ...d.data(), _ref: d.ref, _id: d.id }));
};
