import getBrowserLocale from "@/util/get-browser-locale";

import en from "./locales/en.json";
import fr from "./locales/fr.json";
import ar from "./locales/ar.json";
import es from "./locales/es.json";

export const loadLocaleMessages = () => {
  return { en, fr, ar, es };
};

export const getUsableLocale = (messages) => {
  let real = getBrowserLocale({ countryCodeOnly: true });
  if (messages[real]) return real;
  else return "en";
};
