export const getCapabilities = (teamMembers) => {
  return teamMembers.reduce(
    (acc, user) => {
      // Add the users list of transcription languages
      // to the set of all transcription languages this team
      // is capable of
      acc.transcription = new Set([
        ...acc.transcription,
        ...(user.langsTranscription || []),
      ]);
      // Add the users translation language pairs to the set of all
      // translation language pairs that this team is capable of
      Object.entries(user.langsTranslation || {}).forEach(
        ([srcLang, targetLangs]) => {
          acc.translation[srcLang] = new Set([
            ...(acc.translation[srcLang] || []),
            ...targetLangs,
          ]);
        }
      );
      return acc;
    },
    { translation: {}, transcription: new Set() }
  );
};

export const getCapabilitiesWithCounts = (teamMembers) => {
  return teamMembers.reduce(
    (acc, el) => {
      (el.langsTranscription ?? []).forEach((transcriptionLang) => {
        acc.transcription[transcriptionLang] =
          (acc.transcription[transcriptionLang] || 0) + 1;
      });

      Object.entries(el.langsTranslation ?? {}).map(
        ([srcLang, targetLangs]) => {
          if (!acc.translation[srcLang]) acc.translation[srcLang] = {};
          targetLangs.forEach((targetLang) => {
            acc.translation[srcLang][targetLang] =
              (acc.translation[srcLang][targetLang] || 0) + 1;
          });
        }
      );

      return acc;
    },
    { translation: {}, transcription: {} }
  );
};
