import xlsx from "xlsx";
import { db } from "../db";
import { collection, doc, getDoc } from "firebase/firestore";
import { getMediaForJob } from "../firestore/getMediaForJob";
import { calculateTextScore } from "./score";
import { getLangsForMediaAll } from "../firestore/getLangsForMedia";

const throwIfUndefined = (obj, makeError) => {
  const varName = Object.keys(obj)[0];
  if (!obj[varName]) throw new Error(makeError(varName));
};

/**
 * Parse a spreadsheet into a list of media files
 *
 * @param {File} source Spreadsheet to parse
 * @returns {Promise<any[]>} A list of media objects
 */
export const parseCSVFile = async (source, makeError) => {
  const spreadSheet = xlsx.read(await source.arrayBuffer(), { raw: true });
  return Object.values(spreadSheet.Sheets).flatMap((sheet) =>
    xlsx.utils.sheet_to_json(sheet).map((row) => {
      const { content, title, description, content_type, ...langs } = row;

      // throwIfUndefined({ content }, makeError);
      // throwIfUndefined({ title }, makeError);
      // throwIfUndefined({ description }, makeError);
      // throwIfUndefined({ score }, makeError);

      return {
        srcText: [content],
        description,
        title,
        type: "text",
        content_type,
        // targetLangs: Object.entries(langs)
        //   .filter(([, val]) => val === "Y")
        //   .map(([key]) => key),
        srcLang: Object.entries(langs)
          .filter(([, val]) => val === "S")
          .map(([key]) => key)[0],
        score: calculateTextScore([content]),
      };
    })
  );
};

/**
 * Given a media object with lang references, return the loaded lang objects
 * array
 *
 * @param {*} media media object
 */
const loadLangsIntoMediaObj = async (media) => {
  return (await Promise.all(media.langs.map((langRef) => getDoc(langRef)))).map(
    (d) => d.data()
  );
};

// export const saveJobAsSpreadsheet = async (job) => {
//   const media = await getMediaForJob(job.id);
//   if (!media.length) return;

//   const book = xlsx.utils.book_new();

//   media.sort((a, b) => (a.order < b.order ? -1 : 1));

//   const sheet = xlsx.utils.json_to_sheet(
//     media.map((media) => ({
//       content: media.srcText,
//       title: media.title,
//       description: media.description,
//       ...Object.fromEntries(
//         media.langs.map((l) => [l.targetLang, l.targetText])
//       ),
//     }))
//   );
//   xlsx.utils.book_append_sheet(book, sheet);
//   xlsx.writeFile(book, `${job.title}.csv`);
// };

/**
 * Generate and download a spreadsheet template that teaches users how to input
 * work into the system
 *
 * @param {string} sourceLang lang code for the source language of the template
 * @param {string} title the name of the file
 * @param {*} explanations list of objects with content and title properties
 *                         that are used as rows in the template
 */
export async function generateTemplate(sourceLang, title, explanations) {
  const supportedLangs = (
    await getDoc(doc(collection(db, "config"), "supported_langs"))
  ).data().langs;

  const book = xlsx.utils.book_new();

  const makeExplanation = (title, explanation, description, content_type) => ({
    content: explanation,
    title,
    content_type,
    description,
    ...Object.fromEntries(
      supportedLangs.map((langCode) =>
        langCode === sourceLang
          ? [langCode, "S"]
          : [langCode, Math.random() < 0.5 ? "N" : "Y"]
      )
    ),
  });

  // console.log(sourceLang);
  const sheet = xlsx.utils.json_to_sheet(
    explanations.map(({ title, content, description, content_type }) =>
      makeExplanation(title, content, description, content_type)
    )
  );

  xlsx.utils.book_append_sheet(book, sheet);

  xlsx.writeFile(book, `${title}.csv`);
}

export const buildCSVFile = async (job, lang) => {
  const media = await getMediaForJob(job.id);
  if (!media.length) return;

  console.log("Building CSV File");
  // console.log(media);

  const book = xlsx.utils.book_new();

  media.sort((a, b) => (a.order < b.order ? -1 : 1));

  //get all the lang objects for each media object:
  let promises = [];

  for (let m of media) {
    promises.push(getLangsForMediaAll(m));
  }

  // const langsForMedia = await getLangsForMedia(
  //   mediaForMrk._id,
  //   job.visibility,
  //   lang
  // );

  // const targetText = langsForMedia?.[0].targetText;

  await Promise.all(promises);

  console.log(media);

  const sheet = xlsx.utils.json_to_sheet(
    media.map((media) => ({
      content: media.srcText[0],
      title: media.title,
      description: media.description,
      content_type: media.content_type,
      ...Object.fromEntries(
        media.langs.map((l) => [l.targetLang, l.targetText[0]])
      ),
      ...Object.fromEntries(
        media.langs.map((l) => [`${l.targetLang} status`, l.status])
      ),
    }))
  );

  xlsx.utils.book_append_sheet(book, sheet);

  xlsx.writeFile(book, `${job.title}.csv`);
};

export const isCSV = (fileName) => {
  const parts = fileName.split(".");
  return ["csv", "xlsx"].indexOf(parts[parts.length - 1]) > -1;
};
