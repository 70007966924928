/**
 * Get date representing this time tomorrow
 *
 * @returns {Date}
 */
export const tomorrow = () => {
  const d = new Date();

  d.setDate(d.getDate() + 1);

  return d;
};

// Zero pad a number to a two character string
const zeroPad = (n) => (n < 10 ? `0${n}` : String(n));

// Quasar Input with mask='date' expect the string
// to be in a given format. This function formats any Date object
// to be in the given format
export const formatDateForQInput = (date) => {
  return `${date.getFullYear()}/${zeroPad(date.getMonth() + 1)}/${zeroPad(
    date.getDate()
  )}`;
};
