/**
 * Given a string and a file name, download the string to a new file
 * with the given name
 *
 * @param {string} fileName the name to give the new file
 * @param {string} source the new file's contents
 */
export const downloadStringAsFile = (fileName, source) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
    source
  )}`;
  downloadLink.download = fileName;
  downloadLink.click();
};
