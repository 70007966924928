import { createRouter, createWebHistory } from "vue-router";
import { getCurrentUser } from "../db";
import Login from "@/components/Login";
import Dashboard from "@/pages/Dashboard";
import TeamPage from "@/components/teams/TeamPage";
import JobManagement from "@/components/jobs/JobManagement";
import NewJobForm from "@/components/jobs/NewJobForm";
import JobDetails from "@/components/jobs/JobDetails";
import MediaDetails from "@/components/jobs/MediaDetails";
import LoadJobs from "@/components/jobs/LoadJobs";
import LoadMedia from "@/components/jobs/LoadMedia";
import LandingPage from "@/pages/LandingPage";
import Volunteers from "@/pages/Volunteers";
import Focus from "@/pages/Focus";
import TeamsList from "@/components/teams/TeamsList";
import Projects from "@/pages/Projects";
import Pubs from "@/pages/Pubs";
import Limitless from "@/pages/Limitless";

const routes = [
  {
    path: "/profile",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
    props: {
      tab: "profile",
    },
  },
  {
    path: "/dashboard/:tab?",
    component: Dashboard,
    props: true,
    name: "root_admin",
    meta: {
      requiresAuth: true,
      title: "Admin",
    },
    children: [
      {
        path: "jobs",
        component: LoadJobs,
        props: true,
        meta: {
          title: "Jobs",
        },
        children: [
          {
            path: "",
            name: "jobs",
            meta: {
              title: "All Jobs",
            },
            components: { "job-management": JobManagement },
            props: true,
          },
          {
            path: "new-job",
            meta: {
              title: "New Job",
            },
            name: "new-job",
            components: { "new-job": NewJobForm },
            props: true,
          },
          {
            path: ":jobId",
            name: "job",
            components: { "load-media": LoadMedia },
            props: true,
            children: [
              {
                name: "job-details",
                path: "",
                components: { "job-details": JobDetails },
                props: true,
              },
              {
                name: "media-details",
                meta: {
                  title: "Task Detail",
                },
                path: ":mediaId",
                components: { "media-details": MediaDetails },
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "team",
        meta: { title: "Team" },
        name: "team",
        component: TeamPage,
        props: true,
      },
      {
        path: "teams",
        name: "teams",
        meta: {
          requiresAuth: true,
          requiredRole: "admin",
        },
        component: TeamsList,
        props: true,
      },
      {
        path: "teams/:teamName",
        name: "team",
        meta: {
          requiresAuth: true,
          requiredRole: "orgadmin",
        },
        component: TeamPage,
        props: true,
      },
      {
        path: "focus/:langId",
        meta: {
          requiresAuth: true,
        },
        component: Focus,
        props: true,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:token",
    name: "LoginToken",
    component: Login,
  },
  {
    path: "/volunteers",
    component: Volunteers,
  },
  {
    path: "/projects",
    component: Projects,
  },
  {
    path: "/limitless",
    component: Limitless,
  },
  {
    path: "/media",
    component: Pubs,
  },
  {
    path: "/",
    component: LandingPage,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await getCurrentUser();

  if (currentUser && to.path.startsWith("/login")) {
    return next("/dashboard");
  }

  // Routes can specify that the user must be authenticated to visit them
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // Routes can specify that they require a user to have a specific role to be visited
  const requiredRole = to.matched.find((record) => record.meta.requiredRole)
    ?.meta?.requiredRole;

  // If the route doesn't require auth and doesn't require a specific role,
  // go to the route
  if (!requiresAuth && !requiredRole) {
    return next();
  }

  // Check that the user is authenticated
  if (requiresAuth && !currentUser) {
    return next("/");
  }

  // Check that the user has the required role
  if (requiredRole && currentUser.roles?.indexOf(requiredRole) < 0) {
    return next("/");
  }

  next();
});

export default router;
