import { onMounted, readonly, ref, watch } from "vue";

export const useLoadable = (loadContent, defaultValue) => {
  const loadable = ref({ loading: true, data: defaultValue });

  onMounted(() => {
    loadContent().then((value) => {
      loadable.value.loading = false;
      loadable.value.data = value;
    });
  });

  return loadable;
};

export const useLoadOnMount = (loadContent) => {
  const content = ref(null);
  const loading = ref(true);

  onMounted(() => {
    loadContent().then((value) => {
      content.value = value;
      loading.value = false;
    });
  });

  return {
    data: readonly(content),
    loading: readonly(loading),
  };
};

export const useAsyncAction = (asyncAction) => {
  const loading = ref(false);

  const action = async (...args) => {
    loading.value = true;
    const result = await asyncAction(args);
    loading.value = false;
    return result;
  };

  return { loading: readonly(loading), action };
};

// Given a ref, when the ref changes, run the fetcher and update
// the data ref with the returned value
export const useLoadOnRefChange = (dataSource, fetcher) => {
  const loading = ref(false);
  const data = ref(undefined);

  watch(
    dataSource,
    () => {
      loading.value = true;

      fetcher(dataSource.value)
        .then((result) => (data.value = result))
        .finally(() => (loading.value = false));
    },
    { immediate: true }
  );

  return { data, loading };
};
