import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  getFirestore,
  FieldValue,
  Timestamp,
  doc,
  getDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  functions_url: process.env.VUE_APP_FUNCTION_URL,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

if (process.env.NODE_ENV === "development") {
  // use Firebase emulator for functions if running locally
  // connectFirestoreEmulator(db, "localhost", 8080);
  // connectFunctionsEmulator(functions, "localhost", 5001);
  // connectAuthEmulator(auth, "http://localhost:9099");
  // connectStorageEmulator(storage, "localhost", 9199);
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          const userSnapshot = await getDoc(doc(db, `users/${user.uid}`));
          const userData = userSnapshot.data();

          Object.assign(user, userData);

          unsubscribe();
          resolve(user);
        } else resolve(null);
      },
      reject
    );
  });
};

export const generateCertificate = httpsCallable(
  functions,
  "generateCertificate"
);
export const getStatistics = httpsCallable(functions, "getStatistics");
export const sendComment = httpsCallable(functions, "sendComment");
export const deleteJob = httpsCallable(functions, "deleteJob");
export const createVideoJob = httpsCallable(
  functions,
  "createVideoJobFromClient"
);
export const teammatesWithLangPair = httpsCallable(
  functions,
  "teammatesWithLangPair"
);

export { Timestamp, FieldValue };
export const functionUrl = firebaseConfig.functions_url;
