export const transcriptionScore = (score) =>
  Math.max(1, Math.floor(score * 0.6));
export const verificationScore = (score) =>
  Math.max(1, Math.floor(score * 0.3));

/**
 * Given a src text array, calculate the score for a text media object
 * @param {String[]} srcText
 */
export const calculateTextScore = (srcText) => {
  if (!Array.isArray(srcText)) {
    return 0;
  }

  if (typeof srcText[0] === "undefined") return 0;

  // console.log(srcText);

  return srcText.reduce(
    // One point for every 4 words, minimum one point
    (score, src) => score + Math.max(Math.floor(src.split(" ").length / 4), 1),
    0
  );
};
