import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, getDoc, collection, updateDoc } from "firebase/firestore";
import { firestoreAction } from "vuexfire";
import { db, auth, getStatistics } from "../db";

const actions = {
  async signInAction({ commit }, payload) {
    if (!payload.token) return;

    try {
      await signInWithCustomToken(auth, payload.token);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  async googleSignInAction({ commit }) {
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");

    try {
      const user = await signInWithPopup(auth, provider);
      return user;
    } catch (error) {
      commit("setError", error.message);
    }
  },
  async signOutAction({ commit }) {
    try {
      await signOut(auth);
      commit("setUser", null);
    } catch (error) {
      console.error("Error: ", error);
      commit("setError", error.message);
    }
  },
  authAction({ commit }) {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userSnapshot = await getDoc(doc(db, `users/${user.uid}`));
        const userData = userSnapshot.data();
        Object.assign(user, userData);

        user.ref = userSnapshot.ref;
        user.id = user.uid;

        commit("setUser", user);
      } else {
        commit("setUser", null);
      }
    });
  },
  async acceptConfidentialityAgreement({ commit, state }) {
    const { user } = state;
    const updateValue = { acceptedConfidentiality: true };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  async addNewTranscriptionLang({ commit, state }, newLang) {
    const { user } = state;
    const updateValue = {
      langsTranscription: [...(user.langsTranscription || []), newLang],
    };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  async deleteTranscriptionLang({ commit, state }, langToDelete) {
    const { user } = state;
    const updateValue = {
      langsTranscription: (user.langsTranscription || []).filter(
        (lang) => lang !== langToDelete
      ),
    };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  async addTranslationLangPair({ commit, state }, langPair) {
    const [fromLang, toLang] = langPair;
    const { user } = state;
    const updateValue = {
      langsTranslation: {
        ...user.langsTranslation,
        [fromLang]: [...(user.langsTranslation?.[fromLang] ?? []), toLang],
      },
    };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  async deleteTranslationPair({ commit, state }, langPair) {
    const [fromLang, toLang] = langPair;
    const { user } = state;
    const updateValue = {
      langsTranslation: {
        ...user.langsTranslation,
        [fromLang]: (user.langsTranslation?.[fromLang] ?? []).filter(
          (lang) => lang !== toLang
        ),
      },
    };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  async completeOnboard({ commit, state }, payload) {
    const { user } = state;
    if (!user) return;
    const updateValue =
      payload === "dashboard"
        ? {
            onboardedDashboard: true,
          }
        : { onboardedProfile: true };
    await updateDoc(doc(collection(db, "users"), user.id), updateValue);
    commit("setUser", { ...user, ...updateValue });
  },
  setUserTargetLang: firestoreAction(async ({ state }, payload) => {
    console.log(payload);
    console.log(state);
    await updateDoc(doc(collection(db, "users"), state.user.uid), {
      targetLang: payload,
    });
  }),
  async getStats({ commit, state }) {
    if (!state.stats) {
      const stats = await getStatistics();
      commit("setStats", stats.data);
    }
  },
};

export default actions;
