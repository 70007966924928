import { useStore } from "vuex";
import { computed } from "vue";
import { getDocs, query, collection, where } from "firebase/firestore";
import { db } from "../db";

export const useUser = () => {
  const store = useStore();
  return computed(() => store.state.user);
};

// Return a list of lang pairs that the user has said
// they can translate: eg: [{ srcLang: 'en', targetLang: 'fr' }]
export const useUserLangPairs = () => {
  const user = useUser();

  return computed(() =>
    Object.entries(user.value?.langsTranslation ?? {}).flatMap(
      ([srcLang, targetLangs]) =>
        targetLangs.map((targetLang) => ({ srcLang, targetLang }))
    )
  );
};

export const useLookupUserByEmail = () => {
  return async (usersEmail, usersTeam) => {
    const users = await getDocs(
      query(
        collection(db, "users"),
        where("team", "==", usersTeam),
        where("user_email", "==", usersEmail)
      )
    );
    return users.docs.map((userDoc) => userDoc.data())[0];
  };
};
