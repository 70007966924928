import * as convert from "xml-js";
import { getLangsForMedia } from "../firestore/getLangsForMedia";
import { getMediaForJob } from "../firestore/getMediaForJob";
import { calculateTextScore } from "./score";
import { downloadPathAsString } from "./storage";
// Given a list of XML elements, recursively find all of the text
// elements and stitch them together
//
// Example:
// flattenText([{ type: 'text', text: 'hello' }, { elements: [{ type: 'text', text: 'world; }] }])
//  ==> ['hello world']
const flattenText = (elements) => {
  return elements?.flatMap((el) =>
    el.type === "text" ? el.text : el.elements ? flattenText(el.elements) : []
  );
};

/**
 * Given an XML element and a callback, recursively navigate all of the
 * elements children and call the callback with any text elements
 *
 * @param {*} el The XML element
 * @param {*} callback
 */
const forEachText = (el, callback) => {
  if (el.type === "text") {
    callback(el);
  }
  el?.elements?.forEach((childEl) => {
    forEachText(childEl, callback);
  });
};

/**
 * Given a parsed xml file - get a flat list of all "trans-unit" elements
 *
 * This basically flat maps the trans-units for each file
 */
const transUnits = (parsedXml) =>
  el(parsedXml, "xliff")
    .elements?.filter(
      (xliffChild) =>
        xliffChild.name === "file" &&
        el(xliffChild, "body") &&
        el(el(xliffChild, "body"), "trans-unit")
    )
    ?.flatMap((xliffChild) => el(xliffChild, "body").elements) ?? [];

// Given an xml object, return the given element child (or undefined)
//
// Example:
// const xml = { declaration: { ... }, elements: [{ name: 'file', elements: [...] }, { name: 'xliff', elements: [...] }] };
// el(xml, 'xliff')
//  ==> { name: 'xliff', elements: [...] }
const el = (xmlObject, elementName) =>
  xmlObject?.elements?.find((e) => e.name === elementName);

/**
 * @param {File} source
 */
export const parseSdlXliffFile = async (source) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      const xmlString = reader.result;
      const parsedXml = convert.xml2js(xmlString, { spaces: 4 });

      // Xliff files have `trans-unit` > `seg-source` > `mrk`[] > `[arbitrary-xml]` > `text`
      // So, we create a media object for each mrk, remembering the mrkId, and transUnitId,
      // and this media object has an array of srcText, one srcText for each piece of text
      // in the arbitrary xml within the mrk
      resolve(
        transUnits(parsedXml).flatMap(
          (transUnit) =>
            el(transUnit, "seg-source")
              ?.elements?.map((mrk) => {
                const srcText = flattenText(mrk.elements);
                return {
                  srcText,
                  mrkId: mrk.attributes.mid,
                  transUnitId: transUnit.attributes.id,
                  targetLangs: [],
                  title: `${source.name} - ${transUnit.attributes.id} - ${mrk.attributes.mid}`,
                  type: "text",
                  score: calculateTextScore(srcText),
                };
              })
              .filter((el) => el?.srcText?.length) ?? []
        )
      );
    };
    reader.readAsText(source);
  });
};

/**
 * @param {String} fileName
 */
export const isXliff = (fileName) => {
  const parts = fileName.split(".");
  return ["xliff", "xlf", "xlff"].indexOf(parts[parts.length - 1]) > -1;
};

/**
 * Given a job and a lang, download the xliff file for
 * that job's current translation to that lang
 *
 * @param {*} job
 * @param {string} lang
 * @returns {Promise<string | undefined>} the xliff file contents
 */
export const buildXliffFile = async (job, lang) => {
  console.log("Building XLIF File");
  const media = await getMediaForJob(job.id);
  if (!media.length) return;

  const transUnitIdToMedia = media.reduce((acc, media) => {
    if (!acc[media.transUnitId]) {
      acc[media.transUnitId] = [media];
      return acc;
    }
    acc[media.transUnitId].push(media);
    return acc;
  }, {});

  const originalXliff = await downloadPathAsString(media[0].xliffPath);

  const parsedXml = convert.xml2js(originalXliff, { spaces: 4 });

  await Promise.all(
    // For each file that has a `body > trans-unit` child
    // Here, we iterate over every piece of text in the xliff
    // file and replace it with the corresponding text in the
    // ActionTranslate job. So we basically mutate the parsed xml
    // to contain the translated text
    transUnits(parsedXml).map((transUnit) =>
      Promise.all(
        el(transUnit, "target")?.elements?.map(async (mrk) => {
          const mediaForMrk = transUnitIdToMedia[transUnit.attributes.id].find(
            (media) => media.mrkId === mrk.attributes.mid
          );
          if (!mediaForMrk) return;
          const langsForMedia = await getLangsForMedia(
            mediaForMrk._id,
            job.visibility,
            lang
          );
          console.log(langsForMedia);
          const targetText = langsForMedia?.[0].targetText;
          let countOfTextFound = 0;
          forEachText(mrk, (text) => {
            text.text = targetText[countOfTextFound];
            countOfTextFound += 1;
          });
        }) ?? []
      )
    )
  );

  // console.log(parsedXml);

  return convert.js2xml(parsedXml, { spaces: 4 });
};
