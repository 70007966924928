import { useI18n } from "vue-i18n";
import { codeToLang } from "../../mixins/Language";

export const useTeamStatsTableColumns = () => {
  const { t } = useI18n();

  return [
    {
      name: "srcLang",
      sortable: true,
      label: t("team_stats.src_lang"),
      align: "left",
      field: (row) => codeToLang(row.srcLang),
    },
    {
      name: "targetLang",
      sortable: true,
      align: "left",
      label: t("team_stats.target_lang"),
      field: (row) => codeToLang(row.targetLang),
    },
    {
      name: "awaitingautotranslate",
      label: t("lang_status.awaitingautotranslate"),
      field: (row) => row.awaitingautotranslate ?? 0,
      sortable: true,
    },
    {
      name: "readyformanualtranslate",
      label: t("lang_status.readyformanualtranslate"),
      field: (row) => row.readyformanualtranslate ?? 0,
      sortable: true,
    },
    {
      name: "readyforverify",
      label: t("lang_status.readyforverify"),
      field: (row) => row.readyforverify ?? 0,
      sortable: true,
    },
    {
      name: "finalised",
      label: t("lang_status.finalised"),
      field: (row) => row.finalised ?? 0,
      sortable: true,
    },
    {
      name: "graph",
      label: "",
      field: (row) => row,
      style: "width: 1000px",
      headerStyle: "width: 1000px",
    },
  ];
};
