import { defineStore } from "pinia";
import * as vue from "vue";
import { useUser } from "./firestore/users";
import * as firestore from "firebase/firestore";
import { db } from "./db";
import { computed, watch, watchEffect, ref } from "vue";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";

const makeLangKey = ({ srcLang, targetLang }) => `${srcLang}|${targetLang}`;

export const useUnreadTeamComments = defineStore("unreadTeamComments", () => {
  const lastVisitedTeamComment = vue.ref({});

  const user = useUser();

  // Bind to the last time this user visited each of the team comments
  const unsubscribeLastVisitedTeamComments = ref(undefined);
  const lastVisitedTeamCommentNew = ref(undefined);
  watchEffect(() => {
    if (typeof unsubscribeLastVisitedTeamComments.value === "function") {
      unsubscribeLastVisitedTeamComments.value();
      unsubscribeLastVisitedTeamComments.value = undefined;
    }
    const userId = user.value?.id;
    if (!userId) {
      return;
    }
    unsubscribeLastVisitedTeamComments.value = onSnapshot(
      doc(collection(db, "lastVisitedTeamComments"), userId),
      (snap) => {
        lastVisitedTeamCommentNew.value = snap.data();
      }
    );
  });

  const unreadCommentCounts = ref({});
  watch(lastVisitedTeamCommentNew, async (newValue, oldValue) => {
    if (typeof newValue !== "object" || typeof user.value?.team !== "string") {
      return;
    }
    for (const langPair in newValue) {
      if (newValue[langPair] !== oldValue?.[langPair]) {
        if (
          typeof unreadCommentCounts.value[langPair]?.unsubscribe === "function"
        ) {
          unreadCommentCounts.value[langPair].unsubscribe();
          unreadCommentCounts.value[langPair].unsubscribe = undefined;
        }
        const [srcLang, targetLang] = langPair.split("|");
        if (user.value?.langsTranslation?.[srcLang]?.indexOf(targetLang) < 0) {
          continue;
        }
        const commentsQuery = query(
          collection(db, "teamComments"),
          where("team", "==", user.value.team),
          where("srcLang", "==", srcLang),
          where("targetLang", "==", targetLang),
          where("timestamp", ">=", newValue[langPair])
        );
        unreadCommentCounts.value[langPair] = {
          unsubscribe: onSnapshot(commentsQuery, (snap) => {
            unreadCommentCounts.value[langPair].count = snap.docs.length;
          }),
        };
      }
    }
  });

  const unreadTeamComments = computed(() => {
    return Object.values(unreadCommentCounts.value).reduce(
      (acc, el) => acc + (el?.count ?? 0),
      0
    );
  });

  const visitTeamChat = async (srcLang, targetLang) => {
    if (!user.value || !srcLang || !targetLang) {
      return;
    }
    const now = firestore.Timestamp.now();
    const langKey = makeLangKey({ srcLang, targetLang });
    lastVisitedTeamComment.value[langKey] = now;
    await firestore.setDoc(
      firestore.doc(
        firestore.collection(db, "lastVisitedTeamComments"),
        user.value.id
      ),
      {
        [langKey]: firestore.serverTimestamp(),
      },
      { merge: true }
    );
  };

  return {
    visitTeamChat,
    unreadTeamComments,
    unreadCommentCounts,
  };
});
