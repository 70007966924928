import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

// Filter state derived from the current route's query string
export const useFilterFromQuery = () => {
  const route = useRoute();
  const router = useRouter();

  const filter = computed(() => ({
    srcLang: route.query.srcLang,
    targetLang: route.query.targetLang,
    jobId: route.query.jobId,
    type: route.query.type,
    startAt: route.query.startAt,
    endAt: route.query.endAt,
    showLocked: route.query.showLocked,
  }));

  const filterByJob = (jobId) => {
    // console.log(jobId);
    // if (filter.value.jobId) {
    //   router.push({
    //     path: route.path,
    //     query: {
    //       ...filter.value,
    //       jobId: undefined,
    //       endAt: undefined,
    //       startAt: undefined,
    //     },
    //   });
    //   return;
    // }
    router.push({
      path: route.path,
      query: {
        ...filter.value,
        jobId,
        endAt: undefined,
        startAt: undefined,
      },
    });
  };

  const filterByType = (type) => {
    if (filter.value.type === type) {
      router.push({
        path: route.path,
        query: {
          ...filter.value,
          type: undefined,
          startAt: undefined,
          endAt: undefined,
        },
      });
      return;
    }
    router.push({
      path: route.path,
      query: {
        ...filter.value,
        type,
        startAt: undefined,
        endAt: undefined,
      },
    });
  };

  const filterByLangPair = (langPair, replace = false) => {
    const routerFunction = replace ? router.replace : router.push;
    routerFunction({
      path: route.path,
      query: {
        ...filter.value,
        srcLang: langPair.srcLang,
        targetLang: langPair.targetLang,
        startAt: undefined,
        endAt: undefined,
      },
    });
  };

  const filterByTargetLang = (lang, replace = false) => {
    const routerFunction = replace ? router.replace : router.push;
    routerFunction({
      path: route.path,
      query: {
        ...filter.value,
        srcLang: undefined,
        targetLang: lang,
        startAt: undefined,
        endAt: undefined,
      },
    });
  };

  const toggleShowLocked = () => {
    if (filter.value.showLocked) {
      router.push({
        path: route.path,
        query: {
          ...filter.value,
          showLocked: undefined,
          startAt: undefined,
          endAt: undefined,
        },
      });
      return;
    }
    router.push({
      path: route.path,
      query: {
        ...filter.value,
        showLocked: "yes",
        startAt: undefined,
        endAt: undefined,
      },
    });
  };

  const goToNext = (id) => {
    router.push({
      path: route.path,
      query: {
        ...filter.value,
        endAt: undefined,
        startAt: id,
      },
    });
  };

  const goToPrevious = (id) => {
    router.push({
      path: route.path,
      query: {
        ...filter.value,
        endAt: id,
        startAt: undefined,
      },
    });
  };

  return {
    filter,
    goToNext,
    goToPrevious,
    filterByJob,
    filterByLangPair,
    filterByTargetLang,
    filterByType,
    toggleShowLocked,
  };
};
