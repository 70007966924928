import * as Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { store } from "./store";
import { createI18n } from "vue-i18n";
import MediaPlugin from "@quasar/quasar-ui-qmediaplayer";
import "@quasar/quasar-ui-qmediaplayer/dist/index.css";
import { loadLocaleMessages, getUsableLocale } from "./i18n";
import router from "./router";
import "intro.js/introjs.css";
import "./styles/quasar.scss";
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/Meta.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';;
import "@quasar/extras/material-icons/material-icons.css";
import { createPinia } from "pinia";

import mitt from "mitt";
const emitter = mitt();

let messages = loadLocaleMessages();

const i18n = createI18n({
  locale: getUsableLocale(messages),
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
});

const app = Vue.createApp({
  router,
  render: () => Vue.h(App),
});

app.config.globalProperties.emitter = emitter;
app.config.performance = true;

const pinia = createPinia();

app.use(pinia);
app.use(store);
app.use(i18n);
app.use(MediaPlugin);
app.use(Quasar, {
  config: {},
  plugins: {
    Meta,
    Loading,
    Notify,
    Dialog,
  },
});
app.use(VueAxios, axios);
app.use(router);

app.mixin({
  methods: {
    showError(err) {
      console.log(err);
      this.$q.notify({
        type: "negative",
        position: "top",
        message: err.toString(),
      });
    },
  },
});

app.mount("#app");
