/**
 * Given an array of values and a partition size, partition the array into
 * a list of tuples of size `partitionSize` (or potentially smaller, in the
 * case of the last tuple).
 *
 * Examples:
 * > partitionArray([1,2,3,4,5,6], 3) => [[1,2,3],[4,5,6]]
 * > partitionArray([1,2,3,4,5,6], 4) => [[1,2,3,4],[5,6]]
 *
 * @param {any[]} arr
 * @param {number} partitionSize
 * @returns {any[][]}
 */
export const partitionArray = (arr, partitionSize) => {
  return arr.reduce((acc, el) => {
    const currentArray = acc[acc.length - 1];
    if (!currentArray) return [[el]];
    if (currentArray.length === partitionSize) return acc.concat([[el]]);
    return acc.slice(0, acc.length - 1).concat([[...currentArray, el]]);
  }, []);
};
