import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "./db";
import { useLoadOnMount } from "./useLoadable";

export const useSupportedLangs = () => {
  const { data: langs, loading } = useLoadOnMount(async () =>
    (await getDoc(doc(collection(db, "config"), "supported_langs"))).data()
  );
  return { langs, loading };
};
