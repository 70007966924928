import { limit, query, startAfter, getDocs } from "firebase/firestore";
import { last } from "lodash";
import { ref } from "vue";

/**
 * A way of lazily loading firestore data into a list, using a reactive
 * firebase query
 *
 * @param {import("vue").DeepReadonly<import('vue').Ref<import('firebase/firestore').Query>>} inputQuery Function for creating the query
 * @param {Number} numToLoadAtATime Number of values to load each time `loadMore` is called
 */
export const useLazyLoadedList = (inputQuery, numToLoadAtATime) => {
  const list = ref([]);
  const loading = ref(false);
  const setListItem = (identifyListItem, newValue) => {
    list.value = list.value.map((listItem) =>
      identifyListItem(listItem) ? newValue : listItem
    );
  };
  const loadMore = async () => {
    loading.value = true;
    let listQuery = inputQuery.value;
    if (!listQuery) {
      return;
    }
    listQuery = query(listQuery, limit(numToLoadAtATime));
    if (list.value.length > 0) {
      const lastl = last(list.value);
      const lastRef = lastl._doc;
      listQuery = query(listQuery, startAfter(lastRef));
    }
    const newData = (await getDocs(listQuery)).docs.map((d) => ({
      _doc: d,
      id: d.id,
      ...d.data(),
    }));
    list.value = list.value.concat(newData);
    loading.value = newData.length > 0;
    return newData.length;
  };
  const clear = () => {
    list.value = [];
  };
  return { loadMore, setListItem, loading, list, clear };
};
