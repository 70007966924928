import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../db";

export const getLangsForMedia = async (mediaId, visibility, targetLang) => {
  return (
    await getDocs(
      query(
        collection(db, "langs"),
        where("media", "==", `media/${mediaId}`),
        where("targetLang", "==", targetLang)
      )
    )
  ).docs.map((d) => ({ ...d.data(), _ref: d.ref, _id: d.id }));
};

export const getLangsForMediaAll = async (media) => {
  // console.log(media);
  console.log(`Getting langs for ${media._id}`);
  try {
    const lan = (
      await getDocs(
        query(
          collection(db, "langs"),
          where("media", "==", `media/${media._id}`)
        )
      )
    ).docs.map((d) => ({ ...d.data(), _ref: d.ref, _id: d.id }));

    // console.log(lan);

    media.langs = lan;
  } catch (e) {
    console.log(e);
  }
};
