import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useUser } from "../firestore/users";

const wait = (time) => new Promise((res) => setTimeout(res, time));

/**
 *
 * @param {{id: string, title: string, requiredRole?: string, requiredPath?: string}[]} steps
 */
export const useOnBoarding = (steps) => {
  const router = useRouter();
  const route = useRoute();
  const user = useUser();
  const { t } = useI18n();

  return steps
    .filter((s) => {
      if (s.requiredRole) return user.value.roles.indexOf(s.requiredRole) > -1;
      return true;
    })
    .map(({ id, title, ...options }) => ({
      attachTo: { element: `#onboarding-${id}` },
      content: {
        title: t(`onboarding.${title}`),
        description: t(`onboarding.${id.replaceAll("--", ".")}`),
      },
      on: {
        beforeStep: async () => {
          if (options?.requiredPath && options.requiredPath !== route.path) {
            router.push(options.requiredPath);
            await wait(600);
          }
        },
      },
      options: {
        popper: {
          onFirstUpdate: () => {
            document
              .getElementsByClassName("v-onboarding-btn-primary")?.[0]
              ?.focus();
          },
        },
      },
    }));
};
