import langmap from "langmap";
import { DateTime } from "luxon";

const backupLangMap = {
  am: "Amharic",
  bs: "Bosnian",
  gu: "Gujarati",
  ha: "Hausa",
  hy: "Armenian",
  ka: "Georgian",
  kk: "Kazakh",
  mn: "Mongolian",
  ps: "Pashto",
  si: "Sinhala",
  so: "Somali",
};

export const codeToLang = (val) => {
  let l = langmap[val];
  return l?.englishName ?? backupLangMap[val.toLowerCase()] ?? "?";
};

export default {
  methods: {
    codeToLang(val) {
      try {
        let l = langmap[val];
        return l?.englishName ?? backupLangMap[val.toLowerCase()] ?? "?";
      } catch {
        return "?";
      }
    },
    getTimeFromNow(field) {
      return DateTime.fromJSDate(field.toDate()).toRelative();
    },
  },
};
