/**
 * Given a file, return the length of the video in seconds
 *
 * @param {File} file
 * @returns {Promise<number>}
 */
export const getVideoDurationSeconds = (file) => {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      return resolve(video.duration);
    };
    video.src = URL.createObjectURL(file);
  });
};
