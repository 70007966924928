import { ref, watch, readonly } from "vue";
import { getVideoDurationSeconds } from "./util/video";
import { isXliff, parseSdlXliffFile } from "./util/xliff";
import { isCSV, parseCSVFile } from "./util/spreadsheet";
import { filter } from "lodash";

// Returns a refernce to a file, as well as
// a reference to a list of media that updates asynchronously
// based on the state of the file
export const useMediaFromFile = () => {
  const file = ref(null);
  const media = ref([]);

  watch(file, async () => {
    if (file.value === null) return;

    // If we have an xliff, parse it
    if (isXliff(file.value.name)) {
      console.log("PARSING XLIFF");
      media.value = await parseSdlXliffFile(file.value);
      console.log(media.value);
    } else if (isCSV(file.value.name)) {
      console.log("PARSING CSV");

      const medias = await parseCSVFile(file.value);
      media.value = filter(medias, (m) => {
        return typeof m.srcText[0] !== "undefined";
      });
      console.log(media.value);
    } else {
      try {
        const videoDurationSeconds = await getVideoDurationSeconds(file.value);
        media.value = [
          {
            title: file.value.name.split(".")[0],
            type: "video",
            // Currently: four minutes is one point. Every video is worth at least one point
            score: Math.max(Math.floor(videoDurationSeconds / 60 / 4), 1),
          },
        ];
      } catch (_) {
        media.value = [];
      }
    }
  });

  return { file, media: readonly(media) };
};
