import * as Vue from "vue";
import * as Firestore from "firebase/firestore";
import { useUser } from "@/firestore/users";

/**
 * Each time a new chat from a different user is sent, call the given callback
 *
 * @param {Vue.Ref<Firestore.Query<any>>} newChatQuery query for getting a new chat
 * @param {(_: Firestore.QueryDocumentSnapshot<any>) => any} onNewChat callback to call when a new chat arrives
 */
export const useListenToNewChats = (newChatQuery, onNewChat) => {
  const user = useUser();
  const isFirstSnapshot = Vue.ref(true);

  Vue.watchEffect((onCleanup) => {
    isFirstSnapshot.value = true;
    if (!newChatQuery.value) {
      return;
    }
    const unsubscribe = Firestore.onSnapshot(newChatQuery.value, (newChat) => {
      if (isFirstSnapshot.value) {
        isFirstSnapshot.value = false;
        return;
      }
      const newChatSnap = newChat.docs?.[0];
      // Make sure there is some new chat data
      if (!newChatSnap) {
        return;
      }
      const newChatData = newChatSnap.data();
      // The `onSnapshot` API we're using here sends existing data at the start.
      // We don't care about that, so we guard against the first snapshot here
      // We don't care about chats from the current user
      if (newChatData.user === `users/${user.value.id}`) {
        return;
      }
      onNewChat(newChatSnap);
    });
    onCleanup(unsubscribe);
  });
};

/**
 * Helper for fetching chats infinitely - each time appending them to a reactive list of chats
 *
 * @param {Vue.Ref<Firestore.Query<any>>} newChatQuery Firebase query for fetching the next batch of chats
 * @param {Firestore.QueryDocumentSnapshot<any>[]} onLoadResolved callback that passes the newly fetched snapshots everytime we load more of them
 * @param {Vue.Ref<Firestore.DocumentSnapshot | undefined>} oldestLoadedChatSnapshot
 * @returns function for loading the next batch of chats and a reactive boolean
 *          for whether we are currently loading more chats
 */
export const useInfiniteScrollChats = (
  newChatQuery,
  onLoadResolved,
  oldestLoadedChatSnapshot
) => {
  const chatsLoading = Vue.ref(false);

  const loadMore = async (_index, done) => {
    if (!newChatQuery.value) {
      return done(true);
    }
    // If we don't have any data yet, use the query, otherwise, start after the
    // oldest chat we've loaded so far
    chatsLoading.value = true;
    const newDocs = await Firestore.getDocs(
      oldestLoadedChatSnapshot.value && _index > 1
        ? Firestore.query(
            newChatQuery.value,
            Firestore.startAfter(oldestLoadedChatSnapshot.value)
          )
        : newChatQuery.value
    );
    const oldestChatSnapshot = newDocs.docs?.at(-1)?.data();
    onLoadResolved(newDocs.docs);
    chatsLoading.value = false;
    done(oldestChatSnapshot === undefined);
  };

  return {
    loadMore,
    chatsLoading,
  };
};

/**
 * Expose an API for checking if an element is scrolled all the way to the bottom,
 * as well as scrolling the element to the bottom
 *
 * @param {Vue.Ref<Element | null>} scrollTarget
 * @param {() => void} onScrollToBottom callback for when the element is scrolled to the bottom
 */
export const useScrollToBottom = (scrollTarget, onScrollToBottom) => {
  // Inspired by: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#checking_that_the_user_has_read_a_text
  const isScrolledToBottom = () =>
    scrollTarget.value
      ? scrollTarget.value.scrollHeight -
          Math.round(scrollTarget.value.scrollTop) ===
        scrollTarget.value.clientHeight
      : false;

  Vue.watch(scrollTarget, () => {
    if (!scrollTarget.value) {
      return;
    }
    scrollTarget.value.addEventListener("scroll", () => {
      if (!isScrolledToBottom()) {
        return;
      }
      onScrollToBottom();
    });
  });

  const scrollToBottom = () => {
    const target = scrollTarget.value;

    if (!target) {
      return;
    }

    if (isScrolledToBottom()) {
      return;
    }

    target.scrollTo({
      top: target.scrollHeight,
      behavior: "smooth",
    });
  };

  return {
    scrollToBottom,
    isScrolledToBottom,
  };
};
