import { onSnapshot } from "firebase/firestore";
import { onMounted, readonly, ref, watch } from "vue";

/**
 * @param {import("vue").Ref<import("firebase/firestore").Query<any>>} query Ref<Query<any>>
 * @returns {import("vue").DeepReadonly<import("vue").Ref<import("firebase/firestore").QuerySnapshot<any> | null>>} DeepReadonly<Ref<null | QuerySnapshot>>
 */
export const useBindToQuery = (query) => {
  const unsubscribe = ref(null);

  const documentSnapshot = ref(null);

  const subscribeToQuery = () => {
    if (!query.value) {
      return;
    }
    unsubscribe.value = onSnapshot(query.value, (snap) => {
      documentSnapshot.value = snap;
    });
  };

  onMounted(subscribeToQuery);

  watch(query, async () => {
    if (unsubscribe.value) {
      unsubscribe.value();
    }
    subscribeToQuery();
  });

  return readonly(documentSnapshot);
};
