import { vuexfireMutations } from "vuexfire";

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setJudging(state, payload) {
    state.judging = payload;
  },
  setStats(state, payload) {
    state.stats = payload;
  },
  ...vuexfireMutations,
};

export default mutations;
