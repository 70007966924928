import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useUser } from "./firestore/users";

export const useTeamStats = defineStore("teamStats", () => {
  const user = useUser();
  const stats = ref({});

  const langPairsToLookup = computed(() =>
    Object.entries(user.value?.langsTranslation ?? {})
      .flatMap(([srcLang, targetLangs]) =>
        targetLangs.map((targetLang) => ({ srcLang, targetLang }))
      )
      .concat(
        user.value?.langsTranscription?.map((l) => ({
          srcLang: l,
          targetLang: l,
        }))
      )
  );

  const statsForUser = computed(() => {
    return Object.fromEntries(
      langPairsToLookup.value.map((langPair) => {
        const teamStats =
          stats.value?.[`${langPair?.srcLang}|${langPair?.targetLang}`];
        const usersStats = {
          transcription:
            langPair?.srcLang === langPair?.targetLang
              ? teamStats?.readyformanualtranslate ?? 0
              : 0,
          translation:
            langPair?.srcLang !== langPair?.targetLang
              ? teamStats?.readyformanualtranslate ?? 0
              : 0,
          verification: teamStats?.readyforverify ?? 0,
        };
        return [`${langPair?.srcLang}|${langPair?.targetLang}`, usersStats];
      })
    );
  });

  const totals = computed(() =>
    langPairsToLookup.value.reduce(
      (acc, el) => {
        const usersStats =
          statsForUser.value?.[`${el?.srcLang}|${el?.targetLang}`];
        return {
          verification: acc.verification + usersStats?.verification ?? 0,
          translation: acc.translation + usersStats?.translation ?? 0,
          transcription: acc.transcription + usersStats?.transcription ?? 0,
        };
      },
      { verification: 0, translation: 0, transcription: 0 }
    )
  );

  return {
    stats,
    totals,
    statsForLangPair: statsForUser,
  };
});
