import { getDownloadURL, ref as firebaseStorageRef } from "firebase/storage";
import { storage } from "../db";

/**
 * Given a path to a firebase storage object, return
 * the file's contents as a string
 *
 * @param {string} path
 * @returns {string}
 */
export const downloadPathAsString = (path) =>
  new Promise((res) =>
    getDownloadURL(firebaseStorageRef(storage, path)).then((url) => {
      // console.log("GET " + url);

      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = async () => {
        const blob = xhr.response;
        res(await blob.text());
      };
      xhr.open("GET", url);
      xhr.send();
    })
  );
