/**
 * This file contains a set of hooks for updating the value of a lang item, both in
 * the database as well as in memory. We update the database with firestore's `updateDoc`,
 * and update the in memory value by calling a setter passed in as an argument.
 *
 * Some of these functions use Quasar dialogs to confirm the action
 */

import {
  collection,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { db } from "./db";
import { useUser } from "./firestore/users";
import { useI18n } from "vue-i18n";

export const useUnlockLang = (setLang) => async (item) => {
  const updatedData = {
    activeUser: null,
    markedActiveAt: null,
    lockedAt: null,
  };

  await updateDoc(doc(collection(db, "langs"), item.id), updatedData);

  setLang({ ...item, ...updatedData });
};

export const useLockLang = (setLang) => {
  const user = useUser();

  return async (item) => {
    const updatedData = {
      activeUser: `users/${user.value.id}`,
      markedActiveAt: serverTimestamp(),
      lockedAt: serverTimestamp(),
    };

    await updateDoc(doc(collection(db, "langs"), item.id), updatedData);

    setLang({ ...item, ...updatedData });
  };
};

export const useToggleLockLang = () => {
  const user = useUser();

  return async (item) => {
    if (!user.value?.id || !item?.id) {
      return;
    }
    const updatedData = item.activeUser
      ? {
          activeUser: null,
          markedActiveAt: null,
          lockedAt: null,
        }
      : {
          activeUser: `users/${user.value.id}`,
          markedActiveAt: serverTimestamp(),
          lockedAt: serverTimestamp(),
        };

    await updateDoc(doc(collection(db, "langs"), item.id), updatedData);
  };
};

export const useNeedHelp = (setLang) => {
  const $q = useQuasar();
  const { t } = useI18n();
  const user = useUser();

  return async (item) => {
    // We confirm with a dialog first
    $q.dialog({
      title: t("confirm_need_help"),
      message: t("need_help_explanation"),
      cancel: true,
    }).onOk(async () => {
      const updatedData = {
        targetText: item.targetText || [],
        notes: t("already_attempted_may_be_more_difficult"),
        activeUser: null,
        attemptedBy: `users/${user.value.id}`,
        attemptedAt: serverTimestamp(),
      };
      try {
        await updateDoc(doc(collection(db, "langs"), item.id), updatedData);

        setLang({ ...item, ...updatedData });
      } catch (e) {
        $q.notify({
          type: "negative",
          position: "top",
          message: e.toString(),
        });
      }
    });
  };
};

export const useRejectLang = (setLang) => {
  const $q = useQuasar();
  const { t } = useI18n();

  return async (item) =>
    $q
      .dialog({
        title: t("reject_verify"),
        message: t("reject_reason"),
        prompt: {
          model: "",
          type: "text", // Optional
        },
        cancel: true,
        persistent: true,
      })
      .onOk(async (data) => {
        try {
          const updatedData = {
            status: "readyformanualtranslate",
            notes: t("rejection_reason", [data]),
            activeUser: null,
          };
          await updateDoc(doc(collection(db, "langs"), item.id), updatedData);
          setLang({ ...item, ...updatedData });
        } catch (e) {
          $q.notify({
            type: "negative",
            position: "top",
            message: e.toString(),
          });
        }
      });
};

export const useVerifyLang = (setLang) => {
  const $q = useQuasar();
  const user = useUser();
  const { t } = useI18n();

  return async (item) => {
    try {
      if (!item.type) {
        if (
          typeof item.targetText == "undefined" ||
          (item.targetText.length == 0 && typeof item.type == "undefined")
        )
          throw new Error(t("no_text_entered"));
      }

      const updatedData = {
        status: "finalised",
        verifiedBy: `users/${user.value.id}`,
        activeUser: null,
        verifiedAt: serverTimestamp(),
      };

      await updateDoc(doc(collection(db, "langs"), item.id), updatedData);
      setLang({ ...item, ...updatedData });
    } catch (e) {
      $q.notify({
        type: "negative",
        position: "top",
        message: e.toString(),
      });
    }
  };
};

export const useSubmitTranslationForVerification = (setLang) => {
  const $q = useQuasar();
  const user = useUser();
  const { t } = useI18n();

  return async (item) => {
    try {
      if (
        (typeof item.targetText == "undefined" ||
          item.targetText.length == 0) &&
        typeof item.type == "undefined"
      )
        throw new Error(t("no_text_entered"));
      const updatedData = {
        targetText: item.targetText || "",
        status: "readyforverify",
        activeUser: null,
        translatedBy: `users/${user.value.id}`,
        translatedAt: serverTimestamp(),
      };

      await updateDoc(doc(collection(db, "langs"), item.id), updatedData);
      setLang({ ...item, ...updatedData });
    } catch (e) {
      $q.notify({
        type: "negative",
        position: "top",
        message: e.toString(),
      });
    }
  };
};

export const useSubmitTranscriptionForVerification = (setLang) => {
  const $q = useQuasar();
  const user = useUser();

  return async (item) => {
    try {
      const updatedData = {
        activeUser: null,
        status: "readyforverify",
        transcribedBy: `users/${user.value.id}`,
        transcribedAt: serverTimestamp(),
      };

      await updateDoc(doc(collection(db, "langs"), item.id), updatedData);

      setLang({ ...item, ...updatedData });
    } catch (e) {
      $q.notify({
        type: "negative",
        position: "top",
        message: e.toString(),
      });
    }
  };
};
