import { ref, watchEffect, computed } from "vue";
import { onSnapshot, orderBy, query, startAt, limit } from "firebase/firestore";

const ITEMS_PER_PAGE = 10;

// Given a query, this hook provides facilities for paginating the results of the query.
// This is achieved by specifying which snapshot to "startAt", and to "endAt".
// We over fetch by fetching one doc either side of the current page, that way we can
// tell if there are more pages.
export const usePagination = (filter, baseQuery, getDocWithId) => {
  const startAtSnap = ref(undefined);
  const endAtSnap = ref(undefined);
  const loading = ref(false);
  const docs = ref([]);

  // Logic for loading the snapshot for where to start at or end at
  // for the current page
  watchEffect(async () => {
    if (filter.value.startAt) {
      loading.value = true;
      startAtSnap.value = await getDocWithId(filter.value.startAt);
      loading.value = false;
      endAtSnap.value = undefined;
      return;
    }
    startAtSnap.value = undefined;

    if (filter.value.endAt) {
      loading.value = true;
      endAtSnap.value = await getDocWithId(filter.value.endAt);
      loading.value = false;
      startAt.value = undefined;
      return;
    }
    endAtSnap.value = undefined;
  });

  // Paginate the query by ordering and limiting it based on the filter
  const paginatedQuery = computed(() => {
    if (!baseQuery.value) {
      return;
    }
    let transformedQuery = query(
      baseQuery.value,
      orderBy("order", endAtSnap.value ? "desc" : "asc")
    );
    const countToFetch =
      startAtSnap.value || endAtSnap.value
        ? ITEMS_PER_PAGE + 2
        : ITEMS_PER_PAGE + 1;
    if (startAtSnap.value) {
      transformedQuery = query(transformedQuery, startAt(startAtSnap.value));
    }
    if (endAtSnap.value) {
      transformedQuery = query(transformedQuery, startAt(endAtSnap.value));
    }
    return query(transformedQuery, limit(countToFetch));
  });

  // This effect takes care of subscribing to the docs on this page
  watchEffect(async (onCleanup) => {
    // If we haven't loaded the snapshot to end at yet, let's wait
    if (filter.value.endAt && !endAtSnap.value) {
      return;
    }
    // If we haven't loaded the snapshot to start after yet, let's wait
    if (filter.value.startAt && !startAtSnap.value) {
      return;
    }
    if (!paginatedQuery.value) {
      return;
    }
    loading.value = true;
    // Subscribe to changes for the current page
    const unsubscribe = onSnapshot(paginatedQuery.value, (snap) => {
      loading.value = false;
      const newData = snap.docs;
      if (endAtSnap.value) {
        newData.reverse();
      }
      docs.value = newData;
    });
    onCleanup(() => {
      docs.value = [];
      unsubscribe();
    });
  });

  const pageData = computed(() =>
    docs.value
      .map((d) => ({ ...d.data(), id: d.id }))
      .filter((d, index, array) => {
        if (startAtSnap.value) {
          return index !== 0 && index !== ITEMS_PER_PAGE + 1;
        }
        if (endAtSnap.value) {
          return (
            d.id !== endAtSnap.value.id &&
            (array.length < ITEMS_PER_PAGE + 2 || index !== 0) &&
            (array.length < ITEMS_PER_PAGE + 2 || index !== array.length - 1)
          );
        }
        return index !== ITEMS_PER_PAGE;
      })
  );

  const anotherPageBefore = computed(() => {
    if (startAtSnap.value) {
      return true;
    }
    if (endAtSnap.value) {
      return docs.value.length === ITEMS_PER_PAGE + 2;
    }
    return false;
  });

  const anotherPageAfter = computed(() => {
    if (endAtSnap.value) {
      return true;
    }
    if (startAtSnap.value) {
      return docs.value.length === ITEMS_PER_PAGE + 2;
    }
    return docs.value.length === ITEMS_PER_PAGE + 1;
  });

  const goToNextStartAt = () => docs.value.at(-2)?.id;
  const goToPreviousStartAt = () => docs.value.at(1)?.id;

  return {
    pageData,
    anotherPageBefore,
    anotherPageAfter,
    goToNextStartAt,
    goToPreviousStartAt,
    loading,
  };
};
